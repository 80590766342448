import axios from 'axios'

const API_URL = '/tours/'


// Get dates
const getDates = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

const initiateSripe = async () => {
    const data = {
        storyId: 401,
        connectUrl: "https://talesofreval.ee"
    }    
    const response = await axios.post('https://leplace.leplace-api.com/business/payment/stripe/create-payment-checkout-session', data)
    return response.data
}

const addDate = async (data) => {
    const response = await axios.post(API_URL, data)
    return response.data
}

const addMultipleDates = async (data) => {
    const response = await axios.post(API_URL + "multiple", data)
    return response.data
}

const removeDate = async (data) => {
    const response = await axios.post(API_URL + "remove", data)
    return response.data
}

const getBookings = async () => {
    const response = await axios.get(API_URL + "bookings")
    return response.data
}

const tourService = {
    getDates,
    addDate,
    addMultipleDates,
    removeDate,
    initiateSripe
}

export default tourService