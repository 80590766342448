import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import tourService from './tourService'
import { toast } from 'react-toastify'

const initialState = {
  dates: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get dates
export const getDates = createAsyncThunk('dates/', async (thunkAPI) => {
  try {
    return await tourService.getDates()

  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Add date
export const addDate = createAsyncThunk('dates/add', async (data, thunkAPI) => {
  try {
    return await tourService.addDate(data)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Add date
export const addMultipleDates = createAsyncThunk('dates/add/multiple', async (data, thunkAPI) => {
  try {
    return await tourService.addMultipleDates(data)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Remove date
export const removeDate = createAsyncThunk('dates/remove', async (data, thunkAPI) => {
  try {
    return await tourService.removeDate(data)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const initiateStripe = createAsyncThunk('stripe/initiate', async (data, thunkAPI) => {
  try {
    return await tourService.initiateSripe(data)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})


export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateStripe.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(initiateStripe.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        window.location.href = action.payload.data
      })
      .addCase(initiateStripe.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      .addCase(getDates.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(getDates.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.dates = action.payload
      })
      .addCase(getDates.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      .addCase(addDate.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(addDate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.dates.push(action.payload)
      })
      .addCase(addDate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      .addCase(addMultipleDates.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(addMultipleDates.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.dates = [...state.dates, ...action.payload]
      })
      .addCase(addMultipleDates.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      .addCase(removeDate.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(removeDate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.dates = state.dates.filter((date) => !action.payload.includes(date._id))
      })
      .addCase(removeDate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      
  },
})

export const { reset } = tourSlice.actions
export default tourSlice.reducer